import React, { Component, Fragment } from 'react';
import { BrowserRouter, withRouter } from "react-router-dom";
import './Apps.css';
import Routes from "./Views/Routes";
import RoutesInitial from "./Views/RoutesInitial";
import RoutesUnverified from "./Views/RoutesUnverified";
import Sidebar from "./Components/Sidebar/Sidebar";
import Navigation from "./Components/Navigation/Navigation";
import Modal from 'Components/Modals';
import Loading from 'Components/Loading'
import { NavLink } from "react-router-dom";
import styled, { css } from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestAuth, requestTokenLogin, requestOauthMerge, requestEmailVerification, requestPatchUser } from 'modules/user/actions';
import { requestProducts } from 'modules/product/actions';
import { openModal } from 'modules/modals/actions';
import getQueryParams from "utils/getQueryParams";
import CheckEligibilityAdd from "Components/Modals/CheckEligibilityAdd";

import requestFilteredProducts from "modules/product/requestFilteredProducts"
import { createUser } from "utils/createUser"
import { push } from 'connected-react-router'
import { requestLogout } from 'modules/user/actions';
import checkHost from "utils/checkHost"

import imgGodMode from 'img/elements/godmode.png';

import LinkButton from "Components/LinkButton";
import DeviceDetector from 'device-detector-js';
import { checkGodmode } from 'modules';

import { hot } from 'react-hot-loader/root';

const deviceDetector = new DeviceDetector() || {};
const device = deviceDetector.parse(navigator.userAgent);

function mapStateToProps(state) {
  let applicationProducts = [];
  if (state.user.applications && state.user.applications.length > 0 && state.user.applications[0].products) {
    applicationProducts = state.user.applications[0].products;
  }
  return {
    user: state.user,
    applicationProducts,
    userMeta: state.user.meta,
    router: state.router,
    page: state.page,
    application: state.user.applications && state.user.applications.length > 0 ? state.user.applications[0] : {},
    godmode: state.user.godmode,
    backendIsDead: state.local.hostIsDead,
    browser: state.browser,
    currentModal: state.modals.currentModal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestAuth,
    requestProducts,
    openModal,
    requestTokenLogin,
    requestOauthMerge,
    requestEmailVerification,
    requestPatchUser,
    push,
    requestLogout,
  }, dispatch);
}

function name(firstName, lastName) {
  let name = firstName || '';
  if (firstName && lastName) name += ' ';
  if (lastName) name += lastName || '';
  return name || "Secure Finance";
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasLoaded: false,
      backendIsDead: false,
      greaterThanW645: this.props.browser.greaterThan.w645,
    }
  }

  logout() {
    this.props.requestLogout();
  }

  disableGodmode() {
    window.localStorage.removeItem('godmode');
    this.props.requestPatchUser({
      data: {
        godmode: '',
      }
    })
  }

  requestAuth(callback) {
    //debugger;
    this.props.requestAuth({
      onSuccess: async user => {
        this.setState((prevState) => {
          return { ...prevState, hasLoaded: true }
        });

        //  this.props.openModal("nextStep");
        const godmodeId = window.localStorage.getItem('godmodeId');
        if (callback && callback.godmode && godmodeId) {
          if (user.id.toString() !== godmodeId) {
            delete callback.godmode;
          } else {
            const { success } = await checkGodmode(callback.godmode);
            //console.log('Godmode?', success);
            !success && delete callback.godmode;
          }
          if (callback.godmode) {
            this.props.requestPatchUser({
              data: {
                godmode: callback.godmode,
              }
            })
          }
        }
        // Intercom
        let firstName = this.props.user.firstName || "";
        let lastName = this.props.user.lastName || "";
        // let fullName = name(firstName, lastName);
        // let email = this.props.user.email || "";
        // let phoneNumber = this.props.user.phoneNumber || "";
        // let loanAmount = this.props.userMeta.loanAmount || "";
        // let loanPeriod = this.props.userMeta.loanPeriod || "";
        // let loanPurpose = this.props.userMeta.loanPurpose || "";
        // let loanReason = (this.props.userMeta.isOOLoan) ? "Live-in" : "Investment";
        // let propertyPrice = this.props.userMeta.purchasePrice || "";
        // let interestorprincipal = (this.props.userMeta.isPandILoan) ? "Principal and interest" : "Interest only";

        if (user && !user.error && Object.keys(user).length > 0) {
          requestFilteredProducts(null, 0, user.meta.sortField, user.meta.sortDirection, callback && callback.godmode);
        }
        if (typeof (callback) === 'object') {
          callback && callback.onSuccess && callback.onSuccess(user);
        } else {
          callback && callback.onFail && callback.onFail(user);
        }
      },
      onFail: (result) => {
        this.setState((prevState) => {
          return { ...prevState, hasLoaded: true }
        });
        callback && callback.onFail && callback.onFail(result);
      }
    });
  }

  async componentDidMount() {
    console.log(device);
    console.log(`OS: ${device.os.name} ${device.os.version}`);
    console.log(`Client: ${device.client.name} ${device.client.version}`);
    console.log(`Device: ${device.device.type} ${device.device.brand} ${device.device.model}`);
    console.log(`Resolution: ${window.screen.width} x ${window.screen.height}`);
    // Initial Intercom Load
    console.log("Initial Intercom Load")
    window.Intercom("boot", {
      app_id: "t2b7w54a"
    });

    const setVhProperty = ()=>{
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', () => {
      setVhProperty()
    });
    setVhProperty();

    checkHost();

    let queryParams = getQueryParams();


    if (queryParams.godmode) {
      window.localStorage.setItem('godmode', queryParams.godmode);
      window.localStorage.setItem('godmodeId', queryParams.id);
    }

    const godmode = window.localStorage.getItem('godmode');

    // if (godmode) {
    //   this.props.requestPatchUser({
    //     data: {
    //       godmode: godmode,
    //     }
    //   })
    // }

    if (queryParams.modal) {
      this.props.openModal(queryParams.modal);
    }

    if (queryParams.loginToken && queryParams.type === "oauth") {
      this.requestAuth({
        onSuccess: () => {
          this.setState((prevState) => {
            return { ...prevState, hasLoaded: false }
          });
          this.props.requestOauthMerge({
            email: queryParams.email, token: queryParams.loginToken, callback: () => {
              let returnPage = this.props.user.meta.returnPage && this.props.user.meta.returnPage.slice();
              if (returnPage) {
                this.props.requestPatchUser({
                  userId: this.props.user.id, data: { meta: { returnPage } }, callback: () => {
                    window.location = window.location.origin + "/" + returnPage;
                  }
                });
              } else {
                this.setState((prevState) => {
                  return { ...prevState, hasLoaded: true }
                });
              }
            }
          });
        },
        onFail: () => {
          this.setState((prevState) => {
            return { ...prevState, hasLoaded: false }
          });
          this.props.requestTokenLogin({
            email: queryParams.email, token: queryParams.loginToken, callback: () => {
              this.requestAuth({
                godmode,
              });
            }
          });
        }
      });
    } else if (queryParams.loginToken) {
      this.props.requestTokenLogin({
        email: queryParams.email, token: queryParams.loginToken, userId: queryParams.id, callback: () => {
          this.requestAuth({
            godmode,
          });
        }
      });
    } else if (queryParams.type === "verify") {
      this.requestAuth({
        onSuccess: () => {
          this.props.requestEmailVerification({
            email: queryParams.email, token: queryParams.token,
            callback: (result) => {
              if (result.error) {

              } else {

              }
            }
          });
        },
        onFail: () => {
          this.setState((prevState) => {
            return { ...prevState, hasLoaded: false }
          });
          this.props.requestTokenLogin({
            email: queryParams.email, token: queryParams.token, type: "validate-login", callback: () => {
              this.requestAuth({
                godmode,
              });
            }
          });
        }
      });
    } else if (queryParams.type === "signup") {
      let data = { email: queryParams.email || "" };
      delete queryParams.type;

      queryParams.entryType = 'modal';

      Object.keys(queryParams).forEach(key => {
        if (key === 'firstName' || key === 'lastName' || key === 'phoneNumber' || key === 'phoneNumberType') {
          return data["user-" + key] = queryParams[key];
        } else {
          return data["user_meta-" + key] = queryParams[key];
        }
      });
      createUser(data).then(() => {
        this.setState((prevState) => {
          return { ...prevState, hasLoaded: true }
        });
      }, () => { throw new Error("Error creating user"); });
    } else {
      this.requestAuth({
        godmode,
      });
    }
  }

  mobileOptimised(router) {
    switch(router.location.pathname) {
      case '/search-loans':
      case '/proposal':
      {
        return false;
      }
      default: return true;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let isModalActivated = false;

    if (nextProps.browser.greaterThan.w645 !== prevState.greaterThanW645) {

      // Onboarding Modal
      if ( nextProps.user.phoneNumber
        && !nextProps.currentModal 
        && nextProps.user.mobileVerificationRequired 
        && !nextProps.user.isMobileVerified) {
        isModalActivated = true;
        console.log("Onboarding Modal - Active");
      }

      // SMS Verification Modal
      else if ( nextProps.user.id 
        && nextProps.user.meta 
        && (nextProps.user.meta.entryType === 'main' || nextProps.user.meta.entryType === 'modal') 
        && !nextProps.user.phoneNumber && nextProps.user.clientStage) {
        isModalActivated = true;
        console.log("SMS Verification Modal - Active");
      }

      else {
        isModalActivated = false;
        console.log("SMS Verification Modal or Onboarding Modal - Not Active");
      }

      // && nextProps.browser.greaterThan.w840
      if (!nextProps.browser.greaterThan.w645) {
        if (!isModalActivated ) {
          // console.log("Active - 1");
          window.Intercom("boot", {
            app_id: "t2b7w54a"
          });
        } else {
          // console.log("Not active - 2");
          window.Intercom('shutdown');
        }
        return { greaterThanW645: nextProps.browser.greaterThan.w645 };
      } else {
        window.Intercom("boot", {
          app_id: "t2b7w54a"
        });
        // console.log("Active - 3");
        return { greaterThanW645: nextProps.browser.greaterThan.w645 };
      }
    }
    return {};
  }
   
  render() {

    let productType;
    //console.log("Calculating product type", this.props.application)
    // if ((this.props.applicationProducts.length > 0) && (this.props.page.pageID !== "eligibility") && (this.props.userMeta.eligibilityStart !== 1)) {
    if ((!this.props.userMeta?.eligibility_last_page || this.props.userMeta?.eligibility_last_page < 7) && this.props.applicationProducts.length > 0 && this.props.page.title !== "Loan Qualifier") {
      productType = true;
    } else {
      productType = false;
    }

    if (!this.state.hasLoaded) {
      return "";
    }

    const isInProposal = this.props.page.pageID === 'proposal';

    if (!this.props.user.meta || !this.props.user.clientStage) {
      return (
        <div>
          {(!!this.props.backendIsDead) && 
            <Modal connectionError />
          }
    
  
          <div>
            <Loading />
            <Modal type="initial" />
            <RoutesInitial />
          </div>
        </div>
      );
    }

    return (
      <div>
        {(this.props.backendIsDead)?
          <Modal connectionError />
        :""}
          <div>
          {this.props.user.email && this.mobileOptimised(this.props.router) &&
            <>
              <div className="mobile-unoptimised">
                <div className="center">
                  <h2>Coming soon!</h2>
                  <p>This part of the platform is not yet fully compatible with mobile devices or smaller screen resolutions, but we are working on it!</p>
                  <p>Go to the search loans page?</p>
                  <LinkButton className="search-loan-button" to="/search-loans">Search Loans</LinkButton>

                  <h2 style={{ margin: '30px 0 15px 0' }}>Contact us</h2>
                  <p>Alternatively, if you would like to get in contact with us, please select one of the options below.</p>
                  {/* <button className="lets-chat" onClick={()=>window.Intercom('show')}><span>Let's Chat</span></button> */}
                  <a className="call" href="tel:1300512377"><span>Call Us</span></a>
                </div>
              </div>
            </>
            }
          <Modal />
          
          <CheckEligibilityAdd active={productType && this.props.browser.greaterThan.w840} />
          <Navigation state="active"/>
          <Background/>
          <Sidebar isInProposal={isInProposal}/>
          {(this.props.user.firstName && this.props.user.email)?(<Routes state={productType} clientStage={this.props.user.clientStage} />):(<RoutesUnverified state={productType} />)}
          
        </div>
        {!!this.props.godmode && this.props.browser.greaterThan.w840 &&
          <GodModeToggle onClick={this.disableGodmode.bind(this)} isInProposal={isInProposal}/>
        }
        
      </div>
    );
  }
}

export default process.env.NODE_ENV === "development" ? hot(connect(mapStateToProps, mapDispatchToProps)(App)) : connect(mapStateToProps, mapDispatchToProps)(App);

const Background = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f6f6fa;
`;

const tooltipImg = props => 
  <span data-tooltip='Click to disable God Mode' {...props}>
    <img src={imgGodMode} width='50px'/>
  </span> 

const GodModeToggle = styled(tooltipImg)`
  position: fixed !important;
  bottom: 20px;
  transition: 0.5s left;
  ${p => p.isInProposal ? css`
    left: 100px;
  ` : css`
    left: 280px;
  `}
  z-index: 1000 !important;

  &:before {
    bottom: 110%;
  }

  &:after {

    bottom: 110%;
  }

  @media (max-width: 1440px) {
    left: 100px;
  }

  @media (max-width: 950px) {
    left: 20px;
  }
`;


class RouteListener extends Component {
  constructor(props) {
    super(props);

    checkHost();
  }

  render() {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    );
  }
}

RouteListener = withRouter(RouteListener);