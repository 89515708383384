import React from "react"; import { Component } from "react";

import { bindActionCreators } from 'redux';
import { setTitle, setPageID } from 'modules/page/actions';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setTitle,
    setPageID,
  }, dispatch);
}

class Upload extends Component {
  componentDidMount() {
    document.title = 'Secure Finance - Upload Documents';
    this.props.setTitle("Upload Documents");
    this.props.setPageID("upload");
  }

  render() {
    return (
    <main className={"upload " + this.props.type}>
      <div className="main-container">
        <div className="intro-text">
          <h1>Upload Your Supporting Documents</h1>
          <p>Please upload images of documents below. Some more info about required files etc.</p>
          <ul className="instructions">
            <li><strong>1st option:</strong> Simply upload your documents below</li>
            <li><strong>2nd option:</strong> Email your documents to <a href="mailto:info@securefinance.com.au">info@securefinance.com.au</a></li>
          </ul>
        </div>


        <form action="/file-upload" class="dropzone" id="my-awesome-dropzone"></form>



        <div className="file-uploader">
          <div className="file-uploader-header">
            <ul>
              <li>File Uploader</li>
              <li>Document Type</li>
              <li>Applicant</li>
            </ul>
          </div>
          {uploadingFile()}
          {uploadingFile()}

          <div className="file-submit">
            <p>Please <strong>pick a document type</strong> and <strong>applicant for each file</strong> <br /> and then click button below to submit your files!</p>
            <button className="submit">Submit My Files</button>
          </div>

        </div>


        <div className="upload-container">
          <p>Upload multiple files</p>

          <div className="upload-directions">
            <h2>Drop files here</h2>
            <p>or <span>Browse Files</span> from your computer.</p>
          </div>

        </div>

        <div className="required-doc">
          {
            // Add class to "upload-count" with "complete" for green tick.
          }
          <div className="upload-count"><span>0 out of 4 files uploaded</span></div>
          <div className="required-doc-header">
            <h1>Required Documents</h1>
            <p>Please see the list of items below that we need in order to lodge your application.</p>
          </div>

          <div className="document-table">
            <table>
              <tbody>
                <tr>
                  <th>Document Type</th>
                  <th>Applicant</th>
                  <th>Description</th>
                  <th>Status</th>
                </tr>
                {documentType("Drivers License Front & Back", "Jason James John", "Certified copy of drivers license", "submitted")}
                {documentType("Drivers License Front & Back", "Jason James John", "Certified copy of drivers license", "approved")}
                {documentType("Passport", "Jason James John", "Certified copy of drivers license", "rejected")}
                {documentType("Passport", "Jason James John", "Certified copy of drivers license", "upload")}
              </tbody>
            </table>
          </div>

        </div>

        <div className="security-box">
          <p>Our platform adheres to stringent security standards, and you are covered by a best practice insurance policy.</p>
        </div>

      </div>
    </main>
  );
}
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload);

function statusType(type) {
  switch (type){
    case "submitted": return <td className="submitted">Submitted</td>;
    case "approved": return <td className="approved">Approved</td>;
    case "rejected": return (
      <td className="rejected">
        Rejected (why?)

        <div className="pop-up">
          <div className="content">
            <h4>Reason for rejection</h4>
            <p>Unfortunately this photo was not clearly legible. Please re-upload this file. Thanks!</p>
            <button>Reupload Now</button>
          </div>
        </div>

      </td>
    );
    default: return <td className="upload">Awaiting Upload</td>;
  }
}

function documentType(type, applicant, description, status){
  return (
    <tr className={status} >
      <td>{type}</td>
      <td>{applicant}</td>
      <td>{description}</td>
      {statusType(status)}
    </tr>

  );
}

function uploadingFile(){
  return (
    <div className="uploading-file">

      <div className="upload-status">
        <div className="upload-progress-bar">
          <p className="file-name">tax-2017-2018.pdf</p>
          <p className="upload-speed">5.3MB/s</p>

          <div className="progress-bar-container">
            <div className="progress-bar-indicator" style={{width: ("50%")}}></div>
          </div>

        </div>
        <button className="delete"></button>
      </div>

      <div className="assign-upload">
        <p>payslip-2017-2018.jpg</p>

        <div className="assign-settings">
          <select className="document-type">

          </select>
          <select className="document-applicant">

          </select>
          <button className="comment"></button>
          <button className="view"></button>
          <button className="delete"></button>
        </div>

      </div>

    </div>

  );
}
